import { createApp } from "vue";
import App from "./Index.vue";
import Axios from "./plugins/axios";
import "./registerServiceWorker";
import router from "./router/index";
import renusify from "renusify";
import {
  rApp,
  rContent,
  rMeta,
  rContainer,
  rRow,
  rCol,
  rDivider,
  rSpacer,
  rBtn,
  rIcon,
  rFormCreator,
  rCard,
  rModal,
  rTextInput,
  rCamInput,
  rRadioInput,
  rCheckboxInput,
  rTelInput,
  rSwitchInput,
  rChip,
  rList,
  rTableCrud,
  rTimeAgo,
  rConfirm,
  rMaskInput,
  rProgressCircle,
  rImg,
  rDateInput,
  rSearchBox,
  rTextArea,
  rAddressInput,
  rCheckInput,
  rPasswordInput,
  rUniqueInput,
  rFileInput,
  rSelectInput,
  rNumberInput,
  rRatingInput,
  rTextEditorPreview,
  rAvatar,
  rToolbar,
  rBottomNavigation,
  rCountDown,
  rSlider,
  rSwiper,
  rBtnConfirm,
  rMenu,
  rJsonInput,
  rInfinitePage,
  rInfiniteBox,
  rTimeline,
} from "renusify/components";
import {title, scroll, intersect} from "renusify/directive";

window.app = createApp(App)
  .use(router)
  .use(Axios)
  .use(renusify, {
    rtl: true,
    lang: "fa",
    inputs: {tile: true},
    package: "index",
    components: [
      rApp,
      rContent,
      rMeta,
      rContainer,
      rRow,
      rCol,
      rDivider,
      rSpacer,
      rBtn,
      rIcon,
      rFormCreator,
      rCard,
      rModal,
      rTextInput,
      rCamInput,
      rRadioInput,
      rCheckboxInput,
      rTelInput,
      rSwitchInput,
      rChip,
      rList,
      rTableCrud,
      rTimeAgo,
      rConfirm,
      rMaskInput,
      rProgressCircle,
      rImg,
      rDateInput,
      rSearchBox,
      rTextArea,
      rAddressInput,
      rCheckInput,
      rPasswordInput,
      rUniqueInput,
      rFileInput,
      rSelectInput,
      rNumberInput,
      rRatingInput,
      rTextEditorPreview,
      rAvatar,
      rToolbar,
      rBottomNavigation,
      rCountDown,
      rSlider,
      rSwiper,
      rBtnConfirm,
      rMenu,
      rJsonInput,
      rInfinitePage,
      rInfiniteBox,
      rTimeline,
    ],
    directives: {title, scroll, intersect},
  })
  .mount("#app");
