<template>
  <r-container class="order-cart_e" full-width>
    <template v-if="size > 0">
      <div
          class="d-flex background-one br-lg pa-5"
          v-for="(item, id) in cart"
          :key="id"
      >
        <div class="w-30">
          <r-img :alt="item.title" :src="item.images[0]" auto-size></r-img>
        </div>
        <div class="w-70 ps-2">
          <div class="d-flex h-space-between">
            <span class="display-5">{{ item.title }}</span>
            <r-btn
                icon
                class="color-error-text br-lg elevation-none"
                @click.prevent="del(id)"
            >
              <r-icon v-html="$r.icons.delete"></r-icon>
            </r-btn>
          </div>
          <r-row class="v-end mt-2 mt-md-10">
            <r-col class="col-6">
              <r-number-input
                  class="mt-n3"
                  btnText
                  v-model="$r.store.cart_e[id].count_cart"
                  tile
                  :max="item.count"
                  :min="1"
              ></r-number-input>
            </r-col>
            <r-col class="col-6">
              <span class="text-no-wrap color-warning-text">
                {{ $n(item.price * item.count_cart) }}
                {{ $t($r.store.unit_price) }}
              </span>
            </r-col>
          </r-row>
        </div>
      </div>
    </template>
    <r-row v-else>
      <r-col class="col-12 text-center display-4">
        {{ $t("cart_empty") }}
      </r-col>
    </r-row>
    <r-row v-if="showAction" class="h-end">
      <r-col class="col-auto">
        <r-btn outlined class="color-warning-text" @click.prevent="go()">
          {{ $t("see_cart") }}
        </r-btn>
      </r-col>
      <r-col class="col-auto">
        <r-btn
            outlined
            class="color-info-text"
            @click.prevent="$r.store['showCart'] = false"
        >
          {{ $t("continue_shopping") }}
        </r-btn>
      </r-col>
    </r-row>
  </r-container>
</template>

<script>
export default {
  name: "orderCard_e",
  props: {
    showAction: Boolean,
  },
  computed: {
    size() {
      return this.$helper.size(this.cart);
    },
    cart() {
      this.$storage.set("carts_e", this.$r.store.cart_e);
      return this.$r.store.cart_e;
    },
  },
  methods: {
    del(id) {
      delete this.$r.store.cart_e[id];
      this.$storage.set("carts_e", this.$r.store.cart_e);
    },
    go() {
      this.$r.store["showCart_e"] = false;
      this.$router.push({name: "carts_e"});
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";

.order-cart_e {
  .price-disabled {
    text-decoration: line-through;
  }
}
</style>
